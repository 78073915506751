export const format = (d, type) => {
  if (d) {
    // let timeRegex = "^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-" +
    // "(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})" +
    // "(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29))\\s+" +
    // "([0-1]?[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$"
    // let Regex = new RegExp(timeRegex)
    // if (Regex.test(d)) {
    //   console.log(1111)
    //   console.log(d)
    //   // return d
    //   d = new Date(d).getTime()
    //   console.log(d)

    const date = new Date(d);
    const yyyy = date.getFullYear();
    const MM =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    if (yyyy == "2199" && MM == "12" && dd == "31") {
      return "至今";
    }
    const HH = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const mm =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const ss =
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    switch (type) {
      case "yyyy-MM-dd":
        return `${yyyy}-${MM}-${dd}`;
      // break;
      case "yyyy-MM-dd HH:mm:ss":
        return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
      // break;
      case "yyyy-MM-dd HH:mm":
        return `${yyyy}-${MM}-${dd} ${HH}:${mm}`;
      // break;
      case "yyyy-MM":
        return `${yyyy}-${MM}`;
      case "yyyyMMdd":
        return `${yyyy}${MM}${dd}`;
      // break;
      default:
        return `${yyyy}-${MM}-${dd}`;
      // break;
    }
    // }
  } else {
    return "";
  }
};
